/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 23/08/22
 * Time: 11:44 AM
 * Project: workaxis
 */
html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%
}

footer, nav, section {
    display: block
}

video {
    display: inline-block;
    vertical-align: baseline
}

[hidden] {
    display: none
}

a:active, a:hover {
    outline: 0
}

b {
    font-weight: 700
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block
}

svg:not(:root) {
    overflow: hidden
}

button {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button {
    text-transform: none
}

button {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled] {
    cursor: default
}

button::-moz-focus-inner {
    border: 0;
    padding: 0
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}


.wx-inline-block {
    max-width: 100%;
    display: inline-block
}


html[data-w-dynpage] [data-w-cloak] {
    color: transparent !important
}

p {
    margin-top: 0;
    margin-bottom: 1em
}

[type=button], [type=reset], button {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 700
}

@media screen and (max-width: 767px) {
    .wx-navigation-brand {
        padding-left: 10px
    }
}

.wx-navigation {
    position: relative;
    background: #ddd;
    z-index: 1000
}

.wx-navigation:after, .wx-navigation:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.wx-navigation:after {
    clear: both
}

.wx-navigation-brand {
    position: relative;
    float: left;
    text-decoration: none;
    color: #333
}


@media screen and (max-width: 767px) {
    .wx-navigation-brand {
        padding-left: 10px
    }
}

.wx-maze {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows:auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px
}

body {
    margin: 0;
    min-height: 100%;
    background-color: #0d1117;
    font-family: Inter, sans-serif;
    color: #a7b5c4;
    font-size: 16px;
    line-height: 1.333;
    font-weight: 400
}

h1 {
    margin: 0;
    font-size: 3em;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: -.02em
}

h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 2.25em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -.01em
}

h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.875em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -.02em
}

h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -.01em
}

h5 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.25em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: 0
}

h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: 0
}

.wx-workflow-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    line-height: 1.2;
    font-weight: 600;
    width: 80%;
    letter-spacing: 0
}

a {
    background-color: transparent;
    -webkit-transition: opacity .3s, background-color .3s, color .3s;
    transition: opacity .3s, background-color .3s, color .3s;
    color: #ccdae7;
    text-decoration: none
}

.wx-link-connector {
    position: relative;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: .75em 1.25em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 9999px;
    background-color: #0052cc;
    -webkit-transition: color .2s cubic-bezier(.165, .84, .44, 1);
    transition: color .2s cubic-bezier(.165, .84, .44, 1);
    font-family: 'Inter Display', sans-serif;
    color: #fff;
    font-size: 1.125em;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    letter-spacing: -.01em
}


.wx-main {
    width: 100%;
    position: relative;
    margin-right: auto;
    margin-left: auto
}

.wx-main-child {
    position: relative;
    width: 100%;
    padding-top: 5em;
    padding-bottom: 5em
}

.wx-main-child.wx-breath {
    padding-top: 10em;
    padding-bottom: 10em
}

.wx-text-sm-x {
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 3em;
    line-height: 1.1;
    font-weight: 700;
    letter-spacing: -.02em
}

.wx-text-sm-x.wx-20-character {
    max-width: 20ch
}

.wx-text-xl {
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 5em;
    line-height: 1;
    font-weight: 800;
    letter-spacing: -.03em
}

.wx-text-xl.wx-12-character {
    max-width: 14ch
}

.wx-main-md {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 56em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em
}

.wx-snake-xs {
    position: relative;
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: .375em;
    grid-row-gap: .375em;
    -ms-grid-columns: auto;
    grid-template-columns:auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-snake-xs.wx-child-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-text-xs {
    position: relative;
    z-index: 5;
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 2.5em;
    line-height: 1.1;
    font-weight: 660;
    letter-spacing: -.025em
}

.wx-text-sm {
    position: relative;
    font-size: .875em;
    line-height: 1.25;
    letter-spacing: -.005em
}

.wx-fw-bold {
    font-weight: 700
}

.wx-main-lg {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 64em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em
}

.wx-snake-lg {
    display: -ms-grid;
    display: grid;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-snake-lg.wx-max-width-xl.wx-layout-x-3 {
    margin-right: auto;
    margin-left: auto
}

.wx-snake-lg.wx-child-center {
    display: -ms-grid;
    display: grid;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-overflow-hidden {
    position: relative;
    overflow: hidden
}

.wx-text-md-x {
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 3.5em;
    line-height: 1;
    font-weight: 700;
    letter-spacing: -.02em
}

.wx-text-md-x.wx-20-character {
    max-width: 20ch
}

.wx-text-lg-x {
    position: relative;
    z-index: 5;
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 4em;
    line-height: 1.2;
    font-weight: 700;
    letter-spacing: -.02em
}


.wx-text-lg-x.wx-20-character {
    max-width: 20ch
}

.wx-sub-text-lg {
    font-size: 1.25em;
    line-height: 1.5;
    letter-spacing: -.02em
}

.wx-sub-text-lg.wx-36-character-x {
    max-width: 36ch
}

.wx-icons {
    font-family: 'ICONS ROUNDED', sans-serif;
    font-weight: 400;
    text-transform: none
}


.wx-sub-text-xs {
    font-size: .75em;
    line-height: 1.5
}

.wx-fw-medium {
    font-weight: 500
}

.wx-column-base {
    position: relative;
    z-index: 5;
    display: -ms-grid;
    display: grid;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-column-base.wx-child-center {
    display: -ms-grid;
    display: grid;
    justify-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto;
    text-align: center
}

.wx-sub-text-xl {
    font-family: 'Inter Display', sans-serif;
    font-size: 1.5em;
    letter-spacing: .005em
}

.wx-sub-text-xl.wx-40-character {
    max-width: 40ch
}


.wx-bg-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: none;
    filter: none;
    -o-object-fit: cover;
    object-fit: cover
}

.wx-sub-snake-sm {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    -ms-grid-columns: auto;
    grid-template-columns:auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub-snake-sm.wx-child-middle {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-sub-main-xl {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 72em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em
}

.wx-txt-black {
    color: #0d1117
}

.wx-child {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 48em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em
}

.wx-sub-snake-sm {
    position: relative;
    display: -ms-grid;
    display: grid;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub-snake-sm.wx-child-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-dp-xl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 4em;
    height: 4em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 9999px;
    -o-object-fit: cover;
    object-fit: cover
}

.wx-dp-md {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 2.25em;
    height: 2.25em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 9999px;
    -o-object-fit: cover;
    object-fit: cover
}


.wx-hyper-link {
    color: #3aecba
}

.wx-hyper-link:hover {
    border-bottom-color: transparent;
    opacity: .75
}

.wx-center-child-x {
    padding-top: 10px;
    background: rgba(255, 255, 255, .55)
}

.wx-child-center {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.wx-sub-snake-md {
    position: relative;
    z-index: 5;
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    -ms-grid-columns: auto;
    grid-template-columns:auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub-snake-md.wx-child-middle {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-image-container {
    position: relative;
    z-index: 5;
    width: 100%;
    line-height: 1;
    -o-object-fit: contain;
    object-fit: contain
}

.wx-image-wrapper-x1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 100%
}

.wx-icon-services {
    color: black;
}

.wx-bg-indigo-200 {
    background-color: #bcc6ee
}

.wx-image-wrapper-x16_9 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-bg-white {
    position: relative;
    background-color: #fff
}

.wx-contact-map {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
}


.wx-contact-map-holder {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}

.wx-full-section {
    height: 100%;
}


.wx-bg-red-200 {
    background-color: #f1b5b5
}

.wx-bg-purple-200 {
    background-color: #e0caf8
}

.wx-bg-pink-200 {
    background-color: #f6c8e3
}

.wx-bg-green-200 {
    background-color: #acf1c5
}

.wx-bg-cyan-200 {
    background-color: #9bedf7
}

.wx-bg-amber-200 {
    background-color: #f5dc7a
}

.wx-text-color-white {
    color: #fff
}

.wx-footer {
    opacity: 1;
    -webkit-transition: opacity 250ms, color 250ms;
    transition: opacity 250ms, color 250ms;
    color: #646f7c;
    font-size: .875em
}


.wx-circle {
    border-radius: 9999px
}

.wx-bg-blue-200 {
    background-color: #b0cff7
}

.wx-bulk {
    display: -ms-grid;
    display: grid;
    margin-bottom: 2em;
    grid-auto-columns: 1fr;
    grid-column-gap: 1em;
    grid-row-gap: 4em;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns:1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-bulk.wx-2x {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr
}

.wx-vertical-dynamic {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.wx-bulk-info {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 1.5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #101720
}


.wx-sacrificer {
    line-height: 1;
}

.wx-mini-screen-col {
    justify-items: center;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub-main-xxs {
    display: -ms-grid;
    display: grid;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: .125em;
    grid-row-gap: .125em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub-main-xxs.wx-child-center {
    display: -ms-grid;
    display: grid;
    justify-items: center
}

.wx-sub-snake-base {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: auto;
    grid-template-columns:auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub-snake-base.wx-child-middle {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}


.wx-parent-gap-sm {
    margin: .75em
}

.wx-parent-gap-base {
    margin: 1em
}

.wx-parent-gap-xl {
    margin: 1.75em
}

.wx-parent-gap-2xl {
    margin: 2em
}

.wx-parent-gap-4xl {
    margin: 2.5em
}

.wx-parent-gap-5xl {
    margin: 3em
}

.wx-max-width-xl {
    width: 100%;
    max-width: 40em
}

.wx-m-w-2-x-x {
    width: 100%;
    max-width: 48em
}


.wx-shadowcons {
    box-shadow: inset 0 0 0 1px #1e252c, 0 4px 8px -4px #101720
}

.wx-bg-blue-800 {
    background-color: #1335a7
}

.wx-inner-gap-6xl {
    position: relative;
    padding: 4em
}

.wx-parent-gap-9xl {
    margin: 8em
}

.wx-parent-gap-top {
    position: relative;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0
}

.wx-parent-gap-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0
}

.wx-base-bg {
    height: 100%;
    border-radius: 1px;
    background-image: radial-gradient(circle farthest-corner at -25% -50%, rgba(13, 17, 23, .82) 38%, rgba(13, 17, 23, 0) 48%), radial-gradient(circle farthest-corner at -25% 150%, #0d1117 45%, rgba(13, 17, 23, 0) 89%), radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, .28) 65%, rgba(13, 17, 23, 0) 68%), radial-gradient(circle farthest-corner at -33% -75%, #1f00cc 48%, rgba(131, 5, 49, 0) 56%), radial-gradient(circle farthest-corner at 0 -50%, rgba(13, 17, 23, 0) 64%, #010224 69%, rgba(13, 17, 23, 0) 81%), radial-gradient(circle farthest-corner at 0 -50%, rgba(13, 17, 23, 0) 33%, #7e141f 51%, #0d1117 72%)
}

.wx-black-bg {
    height: 100%;
    background: #0d1117
}

.wx-fill-parent {
    width: 100%;
}

.wx-circle-xs {
    position: relative;
    overflow: hidden;
    border-radius: .5em
}

.wx-footer-x {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 40em;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 4em;
    grid-row-gap: 4em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-x-font-title {
    position: relative;
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7
}

.wx-x-font-title.wx-text-color-white {
    color: #fff
}

.wx-navigation-bar-shadow {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 100%
}

.wx-navigation-list {
    position: relative;
    z-index: 1001;
    width: 100%;
    max-width: 64em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em;
    justify-items: start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    -ms-grid-columns: auto 1fr auto;
    grid-template-columns:auto 1fr auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-navigation-brand-image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    padding: 20px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.wx-navigation-x {
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 80px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent
}

.wx-button-label-x {
    position: relative;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-button-hover-background-x {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: none;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: rgba(0, 0, 0, .1)
}

.wx-20-character {
    max-width: 20ch
}

.wx-footer-x1 {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 4em;
    padding-bottom: 2em
}

.wx-post-card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 20em;
    padding: 1em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: .5em;
    background-color: #171e25;
    box-shadow: inset 0 0 0 1px #1e252c
}

.wx-footer-x1b {
    position: relative;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    margin-top: -4em;
    margin-bottom: 3em;
    padding: 4em 6em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: .5em;
    text-align: center
}

.wx-hex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-mini-circle {
    width: 5em;
    height: 5em;
    margin: .5em;
    padding: 1em;
    border-radius: 9999px;
    background-color: #fff
}

.wx-layout-2x {
    position: relative;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 5em;
    grid-row-gap: 5em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-sub {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 80em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em
}

.wx-teams {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.wx-team {
    width: 20%;
    /*height: 2em;*/
    margin: 1em 2em
}


.wx-text-link-disabled {
    position: relative;
    color: #ccdae7
}

.wx-text-link-disabled:hover {
    color: #3aecba
}

.wx-rainbow {
    padding-right: .05em;
    background-image: -webkit-gradient(linear, left top, right top, from(#ec6464), color-stop(33%, #eecc0b), color-stop(66%, #3dd875), to(#31b3ec));
    background-image: linear-gradient(90deg, #ec6464, #eecc0b 33%, #3dd875 66%, #31b3ec);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.wx-rainbow-green {
    padding-right: .05em;
    background-image: linear-gradient(243deg, #3dd875 23%, #0eb3a0 87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.wx-40-character {
    max-width: 40ch
}


.wx-rainbow-red {
    padding-right: .05em;
    background-image: linear-gradient(207deg, #e23b3b 23%, #6668eb 87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.wx-fillparent-child {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%
}


.wx-child-layout-3x {
    grid-column-gap: 4em;
    grid-row-gap: 4em;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns:1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}


.wx-child-layout-5x {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 4em;
    grid-row-gap: 5em;
    -ms-grid-columns: auto;
    justify-content: center;
    grid-template-columns:.15fr .15fr .15fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-uk {
    grid-column-gap: 4em;
    grid-row-gap: 4em;
    -ms-grid-columns: 1fr 2fr;
    grid-template-columns:1fr 2fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}


.wx-hex-4x {
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns:1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-hex-card {
    padding: 1em;
    background-color: #1e252c
}

.wx-hex-card.wx-circle-xs.wx-shadowcons {
    box-shadow: inset 0 0 0 1px #272e35, 0 4px 8px -4px #101720
}


.wx-foot-info {
    padding-top: 1em;
    border-top: 1px solid #21262d
}

.wx-foot-prints {
    position: relative;
    z-index: 5;
    display: -ms-grid;
    display: grid;
    justify-items: stretch;
    grid-auto-columns: 1fr;
    grid-column-gap: 3em;
    grid-row-gap: 3em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-why-us-text {
    text-align: left;
    width: 13rem
}

@media screen and (max-width: 991px) {
    .wx-why-us-text {
        width: 100%
    }

    .wx-main-child.wx-breath {
        padding-top: 5em;
        padding-bottom: 5em
    }

    .wx-text-sm {
        font-size: 2.5em
    }

    .wx-text-xl {
        font-size: 5em
    }

    .wx-text-xs {
        font-size: 2.25em
    }

    .wx-text-md-x {
        font-size: 3.5em
    }

    .wx-text-lg-x {
        font-size: 3.25em
    }

    .wx-bulk {
        width: 50%;
        margin-right: auto;
        margin-left: auto;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-bulk.wx-2x {
        width: 100%
    }

    .wx-mini-screen-col {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
        grid-row-gap: 2em;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns:1fr 1fr 1fr
    }

    .wx-parent-gap-xl {
        margin: 1.625em
    }

    .wx-parent-gap-2xl {
        margin: 1.875em
    }


    .wx-parent-gap-4xl {
        margin: 2.25em
    }

    .wx-parent-gap-5xl {
        margin: 2.75em
    }


    .wx-inner-gap-6xl {
        padding: 3.5em
    }

    .wx-parent-gap-9xl {
        margin: 7em
    }

    .wx-parent-gap-top {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }

    .wx-parent-gap-bottom {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }

    .wx-footer-x {
        justify-items: center;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr;
        text-align: center
    }

    .wx-navigation-list {
        -ms-grid-columns: auto 1fr;
        grid-template-columns:auto 1fr
    }

    .wx-navigation-x {
        position: static
    }

    .wx-button-hover-background-x {
        display: none
    }

    .wx-layout-2x {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
        grid-column-gap: 5em;
        grid-row-gap: 2.5em;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-child-layout-3x {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-child-layout-5x {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns:1fr 1fr 1fr
    }

    .wx-uk {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-hex-4x {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns:1fr 1fr 1fr
    }

}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 2.25em
    }

    h2 {
        font-size: 1.875em
    }

    h3 {
        font-size: 1.5em
    }

    h4 {
        font-size: 1.375em
    }

    .wx-main-child, .wx-main-child.wx-breath {
        padding-top: 4em;
        padding-bottom: 4em
    }

    .wx-text-sm {
        font-size: 2.25em
    }


    .wx-text-xs {
        font-size: 2.125em
    }

    .wx-text-md-x {
        font-size: 3.125em
    }

    .wx-text-lg-x {
        font-size: 3.25em
    }

    .wx-sub-text-lg {
        font-size: 1.125em
    }

    .wx-sub-text-xl {
        font-size: 1.375em;
        line-height: 1.4
    }


    .wx-sub-snake-md {
        grid-column-gap: 1em;
        grid-row-gap: .5em
    }

    .wx-bulk.wx-2x {
        grid-column-gap: 2em;
        grid-row-gap: 2em;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-sub-snake-base {
        grid-column-gap: .75em;
        grid-row-gap: .75em
    }

    .wx-sub-snake-base.column-mobile {
        grid-auto-flow: row
    }

    .wx-parent-gap-sm {
        margin: .625em
    }

    .wx-parent-gap-base {
        margin: .875em
    }

    .wx-parent-gap-xl {
        margin: 1.5em
    }

    .wx-parent-gap-2xl {
        margin: 1.75em
    }

    .wx-parent-gap-4xl {
        margin: 2.125em
    }

    .wx-parent-gap-5xl {
        margin: 2.5em
    }

    .wx-inner-gap-6xl {
        padding: 3em
    }

    .wx-parent-gap-9xl {
        margin: 6em
    }

    .wx-parent-gap-top {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }

    .wx-parent-gap-bottom {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }

    .wx-footer-x {
        justify-items: center;
        grid-row-gap: 1em;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr;
        text-align: center
    }

    .wx-navigation-x {
        height: 80px
    }

    .wx-footer-x1b {
        padding-right: 2em;
        padding-left: 2em
    }

    .wx-team {
        width: 16%;
        margin-right: 2em;
        margin-left: 2em
    }


    .wx-child-layout-5x {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns:1fr 1fr
    }

    .wx-hex-4x {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns:1fr 1fr
    }
}

@media screen and (max-width: 479px) {
    .wx-link-connector {
        font-size: 1em
    }

    .wx-text-sm {
        font-size: 1.75em
    }

    .wx-text-xs {
        font-size: 1.75em
    }

    .wx-text-md-x {
        font-size: 2.5em
    }

    .wx-text-lg-x {
        font-size: 1.875em;
        letter-spacing: -.01em
    }

    .wx-sub-text-lg {
        font-size: 1.125em
    }

    .wx-sub-text-xs {
        font-size: .75em
    }

    .wx-sub-text-xl {
        font-size: 1.25em
    }

    .wx-bulk {
        width: 100%;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-mini-screen-col {
        width: 100%;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns:1fr 1fr
    }

    .wx-sub-snake-base.column-mobile {
        grid-auto-flow: row
    }

    .wx-parent-gap-xl {
        margin: 1.375em
    }

    .wx-parent-gap-2xl {
        margin: 1.625em
    }


    .wx-parent-gap-4xl {
        margin: 1.875em
    }

    .wx-parent-gap-5xl {
        margin: 2.25em
    }


    .wx-inner-gap-6xl {
        padding: 2.5em
    }


    .wx-parent-gap-9xl {
        margin: 5em
    }

    .wx-parent-gap-top {
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0
    }

    .wx-parent-gap-bottom {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }

    .wx-footer-x1b {
        padding-right: 1.25em;
        padding-left: 1.25em
    }

    .wx-mini-circle {
        width: 4em;
        height: 4em;
        margin: .25em;
        padding: .5em
    }

    .wx-layout-2x {
        width: 100%;
        grid-column-gap: 5em;
        grid-row-gap: 2em
    }

    .wx-inner-gap-6xl {
        padding-right: 1.5em;
        padding-left: 1.5em
    }

    .wx-team {
        width: 25%;
        margin-right: 1em;
        margin-left: 1em
    }

    .wx-child-layout-3x {
        width: 100%
    }

    .wx-child-layout-5x {
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-uk {
        width: 100%;
        grid-column-gap: 2em;
        grid-row-gap: 2em
    }

    .wx-hex-4x {
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .wx-foot-prints {
        grid-column-gap: 3em;
        grid-row-gap: 1.5em
    }
}

@font-face {
    font-family: 'ICONS ROUNDED';
    src: url(https://ik.imagekit.io/kittydev/Workaxis/font/material_icon_uf6DOy7Cx.otf) format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

.wx-x-video-bg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%
}


.wx-slider {
    --duration: 16s;
    --gap: 2rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap)
}

.wx-slider-holder {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll var(--duration) linear infinite
}

@media (prefers-reduced-motion: reduce) {
    .wx-slider-holder {
        animation-play-state: paused
    }
}

@keyframes scroll {
    0% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(calc(-100% - var(--gap)))
    }
}

.work-flow-icon {
    width: 40%
}

.wx-inner-gap-16 {
    padding-top: 16px
}

.wx-inner-gap-32 {
    padding-top: 32px
}


@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?7666655eb52f200cee5dfd7a4def714d") format("truetype"),
    url("./flaticon.woff?7666655eb52f200cee5dfd7a4def714d") format("woff"),
    url("./flaticon.woff2?7666655eb52f200cee5dfd7a4def714d") format("woff2"),
    url("./flaticon.eot?7666655eb52f200cee5dfd7a4def714d#iefix") format("embedded-opentype"),
    url("./flaticon.svg?7666655eb52f200cee5dfd7a4def714d#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon, serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-next:before {
    content: "\f101";
}

.flaticon-clock:before {
    content: "\f102";
}

.flaticon-phone-call:before {
    content: "\f103";
}

.flaticon-envelope:before {
    content: "\f104";
}

.flaticon-location:before {
    content: "\f105";
}

.flaticon-create:before {
    content: "\f106";
}

.flaticon-management:before {
    content: "\f107";
}

.flaticon-secure-shield:before {
    content: "\f108";
}

.flaticon-analytics:before {
    content: "\f109";
}

.flaticon-client:before {
    content: "\f10a";
}

.flaticon-deadline:before {
    content: "\f10b";
}

.flaticon-settings:before {
    content: "\f10c";
}

.flaticon-tech:before {
    content: "\f10d";
}

.flaticon-team:before {
    content: "\f10e";
}

.flaticon-medal:before {
    content: "\f10f";
}

.flaticon-graphic-design:before {
    content: "\f110";
}

.flaticon-web-development:before {
    content: "\f111";
}

.flaticon-analysis:before {
    content: "\f112";
}

.flaticon-cyber-security:before {
    content: "\f113";
}

.flaticon-website:before {
    content: "\f114";
}

.flaticon-android:before {
    content: "\f115";
}

.flaticon-apple:before {
    content: "\f116";
}

.flaticon-programing:before {
    content: "\f117";
}

.flaticon-smartwatch:before {
    content: "\f118";
}

.flaticon-lifeline-on-a-tv-monitor-screen-of-a-medical-program:before {
    content: "\f119";
}

.flaticon-previous:before {
    content: "\f11a";
}

.flaticon-left-quote:before {
    content: "\f11b";
}

.flaticon-3d-file:before {
    content: "\f11c";
}

.flaticon-coding:before {
    content: "\f11d";
}

.flaticon-code:before {
    content: "\f11e";
}

.flaticon-package:before {
    content: "\f11f";
}

.flaticon-cpu:before {
    content: "\f120";
}

.flaticon-guarantee:before {
    content: "\f121";
}

.flaticon-right-quote:before {
    content: "\f122";
}

.flaticon-link:before {
    content: "\f123";
}

.flaticon-development:before {
    content: "\f124";
}

.flaticon-analysis-1:before {
    content: "\f125";
}

.flaticon-pie-chart:before {
    content: "\f126";
}

.flaticon-hierarchical-structure:before {
    content: "\f127";
}

.flaticon-infrastructure:before {
    content: "\f128";
}

.flaticon-software:before {
    content: "\f129";
}

.flaticon-settings-1:before {
    content: "\f12a";
}

.flaticon-pdf-file:before {
    content: "\f12b";
}

.flaticon-file:before {
    content: "\f12c";
}

.flaticon-menu:before {
    content: "\f12d";
}

.flaticon {
    font-size: 30px;
}


.wx-min-s-text-sm {
    position: relative;
    font-size: .875em;
    line-height: 1.25;
    letter-spacing: -.005em
}

.wx-min-s-text-sm.wx-mini-x-text-color {
    color: #a7b5c4
}

.wx-2l-container {
    display: flex;
    gap: 1em;
}

/*-------------------------------------*/


.wx-layout-a {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows:auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px
}

.wx-layout-b {
    position: relative;
    width: 100%;
    padding-top: 5em;
    padding-bottom: 5em
}

.wx-layout-c {
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 3em;
    line-height: 1.1;
    font-weight: 700;
    letter-spacing: -.02em
}

.wx-layout-c.wx-20-character-x {
    max-width: 20ch
}

.wx-layout-9 {
    position: relative;
    z-index: 5;
    width: 100%;
    max-width: 64em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em
}

.wx-layout-x-1 {
    display: -ms-grid;
    display: grid;
    justify-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    -ms-grid-columns: 1fr;
    grid-template-columns:1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-layout-x-1.wx-layout-x-2.wx-layout-x-3 {
    margin-right: auto;
    margin-left: auto
}

.wx-layout-t-1 {
    font-size: 1.25em;
    line-height: 1.5;
    letter-spacing: -.02em
}

.wx-layout-t-1.wx-48-character-x {
    max-width: 48ch
}

.wx-layout-t-1.wx-36-character-x {
    max-width: 36ch
}

.wx-layout-u-1 {
    position: relative;
    z-index: 5;
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    -ms-grid-columns: auto;
    grid-template-columns:auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-layout-1-x-1 {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    -ms-grid-columns: auto;
    grid-template-columns:auto;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-m-b-x-1 {
    margin: 1em
}

.wx-layout-x-2 {
    width: 100%;
    max-width: 40em
}

.wx-m-w-2-x-x {
    width: 100%;
    max-width: 48em
}

.wx-b-m-1-x {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0
}

.wx-r-xs-x {
    position: relative;
    overflow: hidden;
    border-radius: .5em
}

.wx-f-t-ft-x {
    position: relative;
    font-family: 'Inter Display', sans-serif;
    color: #ccdae7;
    font-size: 1.2em
}

.wx-20-character-x {
    max-width: 20ch
}

.wx-layout-1x1-x {
    position: relative;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 5em;
    grid-row-gap: 5em;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.wx-r-s-w-x {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 3em 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.wx-hc-hc-x {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1.5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

@media screen and (max-width: 991px) {
    .wx-layout-c {
        font-size: 2.5em
    }

    .wx-b-m-1-x {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }

    .wx-layout-1x1-x {
        width: 75%;
        margin-right: auto;
        margin-left: auto;
        grid-column-gap: 5em;
        grid-row-gap: 2.5em;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }
}

@media screen and (max-width: 767px) {
    .wx-layout-b {
        padding-top: 4em;
        padding-bottom: 4em
    }

    .wx-layout-c {
        font-size: 2.25em
    }

    .wx-layout-t-1 {
        font-size: 1.125em
    }

    .wx-layout-u-1 {
        grid-column-gap: 1em;
        grid-row-gap: .5em
    }

    .wx-layout-1-x-1 {
        grid-column-gap: .75em;
        grid-row-gap: .75em
    }

    .wx-m-b-x-1 {
        margin: .875em
    }

    .wx-b-m-1-x {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }
}

@media screen and (max-width: 479px) {
    .wx-layout-c {
        font-size: 1.75em
    }


    .wx-layout-t-1 {
        font-size: 1.125em
    }

    .wx-b-m-1-x {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0
    }

    .wx-layout-1x1-x {
        width: 100%;
        grid-column-gap: 5em;
        grid-row-gap: 2em
    }

    .wx-r-s-w-x {
        padding: 0
    }
}

.contact-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.contact-form {
    height: 585px;
    width: 70%;
    border-radius: 10px;
    overflow: clip;
}


@media (min-width: 768px) {
    .contact-form {
        height: 500px;
    }
}
